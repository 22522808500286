import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    background-color: teal;
    transform: scale(1.2);
    opacity: 0;
  }
  to {
    background-color: white;
    transform: scale(1);
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  border: solid 1px black;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 300ms ease-out;
`;

const Title = styled.div`
  display: flex;
  text-align: center;
`;

export default function Card({ title }) {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  );
}
