export async function query<T>(sql: string): Promise<T[]> {
  const response = await fetch("/api/query", {
    method: "POST",
    body: JSON.stringify({ sql }),
    headers: { "Content-Type": "application/json" },
  });
  const body = await response.json();
  console.log(body);
  if (body.error) {
    throw new Error(body.error);
  }
  return body.rows;
}
