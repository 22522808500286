import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { query } from "./api";
import Card from "./Card";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100vh;
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export default function App() {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    query<{}>("SELECT * FROM items").then(setItems);
  }, []);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "n") {
        setItems(([_, ...rest]) => rest);
      }
    }
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const [item] = items;
  return (
    <Container>
      <GlobalStyle />
      {item ? <Card key={item.id} title={item?.title} /> : null}
    </Container>
  );
}
